/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/**
 * TOOLTIPS (TIPPY)
 * ----------------------------------------------------------------------------
 */
body .tippy-box {
	font-size: 16px;
}

body .tippy-box[data-theme="light"] {
	background-color: #fff;
	color: #000;
	box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.4);

	.tippy-svg-arrow {
		fill: #fff;
	}
}

ion-fab.fab-horizontal-end.fab-vertical-bottom {
	bottom: calc(10px + var(--ion-safe-area-bottom, 0px));
}

/**
 * MODAL CHANGES
 */
ion-modal.modal-medium {
	--max-height: 100%;
	--max-width: 1330px;
	--height: 90%;
	--width: 85%;
	--border-radius: 20px;
}

ion-modal.modal-small {
	--max-height: 100%;
	--max-width: 100%;
	--height: 70%;
	--width: 65%;
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
	--border-radius: 20px;
}

ion-modal.img-preview-modal {
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

@media screen and (min-width: 768px) {
	ion-modal.img-preview-modal {
		--border-radius: 20px;
		--height: 95%;
		--width: 95%;
	}
}

/**
 * MARGIN UTILITY
 * ----------------------------------------------------------------------------
 */
.mt-s {
	margin-top: 0.5rem;
}

.mr-s {
	margin-right: 0.5rem;
}

.mb-s {
	margin-bottom: 0.5rem;
}

.ml-s {
	margin-left: 0.5rem;
}

.mt-m {
	margin-top: 1.2rem;
}

.mr-m {
	margin-right: 1.2rem;
}

.mb-m {
	margin-bottom: 1.2rem;
}

.ml-m {
	margin-left: 1.2rem;
}

.mt-l {
	margin-top: 2rem;
}

.mr-l {
	margin-right: 2rem;
}

.mb-l {
	margin-bottom: 2rem;
}

.ml-l {
	margin-left: 2rem;
}

.mt-0 {
	margin-top: 0;
}

.mr-0 {
	margin-right: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.ml-0 {
	margin-left: 0;
}

/**
 * PADDING UTILITY
 * ----------------------------------------------------------------------------
 */
.pt-s {
	padding-top: 0.5rem;
}

.pr-s {
	padding-right: 0.5rem;
}

.pb-s {
	padding-bottom: 0.5rem;
}

.pl-s {
	padding-left: 0.5rem;
}

.pt-m {
	padding-top: 1rem;
}

.pr-m {
	padding-right: 1rem;
}

.pb-m {
	padding-bottom: 1rem;
}

.pl-m {
	padding-left: 1rem;
}

.pt-0 {
	padding-top: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.no-padding {
	padding: 0 !important;
	--padding-start: 0 !important;
	--padding-end: 0 !important;
	--padding-top: 0 !important;
	--padding-bottom: 0 !important;
}

.no-padding-start {
	--padding-start: 0 !important;
}

.no-padding-end {
	--padding-end: 0 !important;
}

/**
 * BUTTONS
 * ----------------------------------------------------------------------------
 */
ion-button,
ion-buttons > ion-button {
	text-transform: none;
	font-weight: 500;
	letter-spacing: 0;
	height: auto;
	font-size: 1rem;
	--padding-top: 0.6rem;
	--padding-bottom: 0.6rem;
	--padding-start: 1.1rem;
	--padding-end: 1.1rem;

	&.button-small {
		font-size: 0.85rem;
		--padding-top: 0.4rem;
		--padding-bottom: 0.4rem;
		--padding-start: 1.1rem;
		--padding-end: 1.1rem;
	}

	&.button-large {
		font-size: 1.1rem;
		--padding-top: 0.7rem;
		--padding-bottom: 0.7rem;
		--padding-start: 1.6rem;
		--padding-end: 1.6rem;
	}

	&.round {
		--padding-top: 0;
		--padding-bottom: 0;
		--padding-start: 0;
		--padding-end: 0;
		height: 2.3rem;
		width: 2.3rem;
		--border-radius: 100% !important;
		border-radius: 100% !important;

		&:hover {
			--border-radius: 100% !important;
			border-radius: 100% !important;
		}

		&.button-small {
			font-size: 0.9rem;
			height: 2rem;
			width: 2rem;
		}

		&.button-large {
			font-size: 1.2rem;
			height: 2.8rem;
			width: 2.8rem;
		}

		&.button-outline,
		&.button-clear {
			transition: background 0.2s ease-out;

			&:hover {
				transition: background 0.2s ease-out;
				border-radius: var(--border-radius);
				background: var(--ion-color-primary);
				color: #fff;

				* {
					color: #fff;
				}
			}

			&[color="warning"] {
				&:hover {
					background: var(--ion-color-warning);
					color: #000;

					* {
						color: #000;
					}
				}
			}

			&[color="danger"] {
				&:hover {
					background: var(--ion-color-danger);
					color: #fff;

					* {
						color: #fff;
					}
				}
			}

			&[color="success"] {
				&:hover {
					background: var(--ion-color-success);
					color: #fff;

					* {
						color: #fff;
					}
				}
			}
		}
	}

	&.arnold-is-loading {
		position: relative;

		&::part(native) {
			cursor: wait;
		}

		fa-icon,
		&:hover fa-icon,
		&:hover fa-icon * {
			color: transparent !important;
		}

		&::before {
			position: absolute;
			left: 11px;
			top: 50%;
			z-index: 9;
			pointer-events: none;
			text-align: center;
			margin-top: -11px;
			font-family: "Font Awesome 5 Pro";
			font-weight: 400;
			content: "\f3f4";
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-webkit-animation: rotating 0.75s linear infinite;
			-moz-animation: rotating 0.75s linear infinite;
			-o-animation: rotating 0.75s linear infinite;
			animation: rotating 0.75s linear infinite;
		}
	}

	fa-icon[slot="start"] {
		margin-right: 0.6em;
	}

	fa-icon[slot="end"] {
		margin-left: 0.6em;
	}

	&[size="large"] {
		height: 36px;
	}

	&[fill="solid"] {
		--box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}

	&[fill="solid"]:not(.button-round) {
		--border-radius: 4px;
	}
}

/**
 * Barcode / QR Scanner tricks
 * ----------------------------------------------------------------------------
 **/
.scanner-visible {
	background: transparent;
	--background: transparent;

	#main {
		background: transparent;
		--background: transparent;

		& > ion-content {
			--background: transparent;
		}
	}

	ion-content {
		background: transparent;
		--background: transparent;
	}

	&.scanner-in-modal {
		#main {
			display: none;
		}

		ion-modal {
			--background: transparent;

			ion-content {
				--background: transparent;
			}

			ion-backdrop {
				display: none;
			}
		}
	}

	&.scanner-in-dialog {
		#main {
			visibility: hidden;
		}

		app-dialog {
			.arnold-dialog-fullscreen {
				padding: 0;
				background: transparent;
				--background: transparent;

				.ion-text-center {
					height: 100%;
				}

				.arnold-dialog-header {
					background: var(--ion-background-color);
				}

				app-code-scanner {
					display: block;
					height: calc(100% - calc(56px + var(--ion-safe-area-top)));
				}
			}
		}
	}

	&.scanner-in-dialog.scanner-mobile {
		#main {
			visibility: hidden;
			display: none;
		}

		ion-modal {
			display: none;
		}
	}
}

.dialog-scanner {
	text-align: left !important;
}

.no-brackground {
	background: transparent !important;
}
